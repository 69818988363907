*{
    margin: 0;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
    position: fixed;
    top: 0;
    width: 100%
}

/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
    padding-top: 102px;
}

body{
    background-color: whitesmoke;
}

.body-scaffold{
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas: 'sidebar body';
}

.jobs-body-scaffold{
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-areas: 'sidebar body';
}

.header-wrapper{
    z-index: 1000;
    display: grid;
    grid-template-columns: 20% 80%;
    max-height: 130px;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.header-logo-wrapper{
    display: grid;
    grid-template-columns: 100%;
    column-count: 1;
    /*max-width: calc(400px - 10vw);*/
}

.navlink-wrapper{
    margin-top: 65px;
    margin-right: 25px;
    margin-left: auto;
}

.navlink-wrapper a{
    color: grey;
    text-decoration: none;
}

.navlink-cta-scaffold{
    display: grid;
    grid-template-columns: 30% 70%;
}

.icon-button-wrapper{
    margin-top: 65px;
    display: inline-flex;
}

.sidebar{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    /*height: 75vh;*/
    width: 70%;
    margin: auto;
    margin-top: 5vh;
}

.jobsearch-sidebar{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    /*min-height: 50vh;*/
    width: 70%;
    margin: auto;
    margin-top: 15vh;
}

.bs-subtle{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.job-offer{


    border-radius: 5px;
    padding: 10px;
    background-color: white;
    width: 85%;
    margin: auto;
    margin-top: 5vh;
}

.footer-wrapper{
    padding: 20px;
    width: 50vw;
    margin: auto;
    margin-top: 10vh;
}

.sub-emphasised-text{
    color: grey;
    font-weight: normal;
    font-size: small;
}

.emphasised-text{
    font-weight: bold;
    font-size: medium;
}

.home-content-body{
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 25px;
}